import React from 'react';
import { InputGroup } from '../tsUtils';

import { Grid, Avatar, Typography, debounce } from '@mui/material';

import '../style/Preview/ClientSets.scss';

interface Props {
  inputs: InputGroup | null;
  cardId: string;
}

function PreviewCardInputs(props: Props) {

  return (
    <div className="cardInputs">
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography fontSize="x-small" variant="button">
            No.
          </Typography>
        </Grid>
        {props.inputs && 
          props.inputs.map(({key, value}) => {
            return (
              <Grid
                item
                xs={2.75}
                sx={{
                  flex: 1,

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="button" fontSize="x-small" component="span">
                  {key}
                </Typography>
                {value.map((input: string) => {
                  return (
                    <div className="input-cell-cell">
                    <span style={{ textAlign: 'center', borderRight: '1px solid #ccc' }}>
                      {input}
                    </span>
                    <span style={{ textAlign: 'center' }}></span>
                  </div>
                  );
                })}
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  );
}

export default PreviewCardInputs;
