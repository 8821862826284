import { User } from "../../tsUtils/userTypes";
import { ClientForm } from "../clients/components/ClientForm";
import * as paths from "./paths";
import { getAuthHeader } from "./paths";

export async function getClients(): Promise<User[]> {
  let endpoint = `${paths.COACHCLIENT}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: "GET",
    headers,
  }).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}
export async function createClient(client: ClientForm): Promise<User> {
  let endpoint = `${paths.COACHCLIENT}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "POST",
    headers,
    body: JSON.stringify(client),
  }).then(
    (response: Response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}

export async function deleteClient(id: number): Promise<any> {
  let endpoint = `${paths.COACHCLIENT}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  }).then(
    (response: Response) => {
      if (response.ok) {
        return response;
      }
    },
    error => {
      return { error };
    },
  );
}

export async function updateClient(client: ClientForm, clientId: number): Promise<User> {
  let endpoint = `${paths.COACHCLIENT}/${clientId}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "PUT",
    headers,
    body: JSON.stringify(client),
  }).then(
    (response: Response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}

export async function inviteClient(clientId: number): Promise<Response> {
  let endpoint = `${paths.COACHCLIENT}/${clientId}/invite`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
  })
}