import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import {getPrograms, getProgramsClient} from '../../services/API';
import '../../style/Coach/ListPage.scss';
import { RootState } from '../../store/store';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { AccountType, } from '../../tsUtils';
import { orderBy } from 'lodash';
import { templateSelectors } from '../../+store/templates/reducers';
import { TemplateInterface } from '../../tsUtils/templateTypes';
import ProgramFromTemplateForm from '../programs/dialogs/ProgramFromTemplate';
import ViewTemplate from './dialogs/ViewTemplate.';
import { getExercises } from '../../+store/definitions/exercises/actionTypes';
import PopupMenu from '../../common/PopupMenu';
import { ContentCopyOutlined, DeleteOutlined, MoreVert } from '@mui/icons-material';
import { templateActions } from '../../+store/templates/actionTypes';

const TemplateStyles = {
  templateName: {
    cursor: 'pointer',
    '&:hover': {
      color: '#15998E',
    },
  },
};

function Templatelist(props: any) {
  const navigate = useNavigate();
  const templates = useSelector((state: RootState) => templateSelectors.selectAll(state));
  const loading = useSelector((state: RootState) => state.templates.loading);
  const loaded = useSelector((state: RootState) => state.templates.loaded);
  const [open, setOpen] = useState<boolean>(false);
  const [createProgramOpen, setCreateProgramOpen] = useState<boolean>(false);
  const [viewTemplateOpen, setViewTemplateOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<TemplateInterface | null>(null);
  const userType = useSelector((state: RootState) => state.auth.user?.account_type);
  const dispatch = useDispatch();

  function confirmedDelete() {
    if (selectedRow) dispatch(templateActions.deleteTemplateRequest(selectedRow.id));
    if (loaded) {
      handleClose();
    }
  }
  const handleClose = () => {
    setOpen(false);
    setCreateProgramOpen(false);
    setViewTemplateOpen(false)
    setSelectedRow(null);
  };

  // FOR PAGINATION
  useEffect(() => {
    orderBy(templates, ['modified'], 'asc');
    return () => {};
  }, [templates]);

  const defaultColDef = {
    // set every column width
    minWidth: 120,
  };

  useEffect(() => {
    dispatch(getExercises());
  }, [dispatch]);

  const deleteItem = (data: TemplateInterface) => {
    setSelectedRow(data);
    setOpen(true);
  };

  const createProgram = (data: TemplateInterface) => {
    setSelectedRow(data);
    setCreateProgramOpen(true);
  };

  const editItem = (data: TemplateInterface) => {
    console.log('edit', data);
  };

  const viewTemplate = (data: TemplateInterface) => {
    setSelectedRow(data);
    setViewTemplateOpen(true);
  };

  const options: ('edit' | 'copy' | 'delete')[] = ['delete', 'copy'];

  return (
    <div className="listPage">
      <div className={userType === AccountType.Client ? 'listgroup-client' : 'listgroup'}>
        <div style={{marginTop: '10px'}} className="listDiv">
          <Box color="primary" className="list-header-row">
            <Typography
              component="div"
              className="list-row-item item1"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Template
            </Typography>
            {userType !== AccountType.Client && (
              <Typography
                component="div"
                className="list-row-item item2"
                style={{ lineHeight: 1.1 }}
                variant="button"
              >
                Creator
              </Typography>
            )}
            <Typography
              component="div"
              className="list-row-item item3"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              {userType == AccountType.Client ? 'Creator' : 'Macro'}
            </Typography>
          </Box>
          {loading ? (
            <div className="loading-cont">
              <CircularProgress />
            </div>
          ) : (
            <div className="list-body">
              {templates.length > 0 ? (
                templates.map(template =>
                  userType === AccountType.Client ? (
                    <div onClick={() => viewTemplate(template)} key={template.id} className="list-row">
                      <Typography
                        variant="button"
                        sx={TemplateStyles.templateName}
                        className="list-row-item item1"
                        component="div"
                      >
                        {template.name}
                      </Typography>

                      <div className="user-pill-cont item2">
                        <div className="user-pill">
                          <Typography variant="caption" component="span">
                            {template.creator.first_name
                              ? template.creator.first_name + ' ' + template.creator.last_name
                              : template.creator.username}
                          </Typography>
                        </div>
                      </div>
                      <Typography variant="button" className="list-row-item item4" component="div">
                        {template.macro}
                      </Typography>
                    </div>
                  ) : (
                    <div onClick={() => viewTemplate(template)} key={template.id} className="list-row">
                      <Typography
                        variant="button"
                        sx={TemplateStyles.templateName}
                        className="list-row-item item1"
                        component="div"
                      >
                        {template.name}
                      </Typography>

                      <Typography variant="button" className="list-row-item item2" component="div">
                        {template.creator.first_name
                          ? template.creator.first_name + ' ' + template.creator.last_name
                          : template.creator.username}
                      </Typography>

                      <Typography variant="button" className="list-row-item item3" component="div">
                        {template.macro}
                      </Typography>
                      
                      <div className="list-row-item item5">
                      <PopupMenu 
                        menuItems={[
                          {text: 'New Program', action: () => createProgram(template), icon: <ContentCopyOutlined />},
                          {text: 'Delete', action: () => deleteItem(template), icon: <DeleteOutlined />},
                        ]} 
                        icon={<MoreVert />} 
                        popoverId={`tempalte-list-${template.id}`} 
                        />
                      </div>
                      
                    </div>
                  ),
                )
              ) : (
                <div
                  style={{
                    gridRow: '1/-1',
                    gridColumn: '1/-1',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    color="InactiveCaptionText"
                    sx={{ width: '100%' }}
                    component="span"
                    variant="h4"
                  >
                    No Templates Found
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to delete template <strong>{selectedRow?.name}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={confirmedDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {userType !== AccountType.Client && selectedRow && (
        <ProgramFromTemplateForm
          open={createProgramOpen}
          template={selectedRow}
          onClose={handleClose}
        />
      )}
      {selectedRow && (
        <ViewTemplate open={viewTemplateOpen} template={selectedRow} onClose={handleClose} />
      )}
    </div>
  );
}

export default Templatelist;
