import React from 'react';
import { PageActions } from '../../common/PageActions';
import { Add, DeleteOutlined, MoreVert } from '@mui/icons-material';
import { openCreateAssessment } from '../../store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AssessmentSelectors } from '../../+store/assessments/reducers';
import PopupMenu from '../../common/PopupMenu';
import { assessmentActions } from '../../+store/assessments/actionTypes';
import { Assessment } from '../../tsUtils/assessmentTypes';
import { useNavigate } from 'react-router-dom';

export default function AssessmentList() {
  const assessments = useSelector((state: any) => AssessmentSelectors.selectAll(state));
  const loading = useSelector((state: any) => state.assessments.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createProduct = () => {
    dispatch(openCreateAssessment({}));
  }

  const deleteAssessment = ( assessment: Assessment) => {
    dispatch(assessmentActions.deleteAssessment(assessment.id!));
  }

  const viewAssessment = (assessment: Assessment) => {
    navigate(`/coach/assessments/${assessment.id}`);
  }

  return (
    <div className="listPage">
      <div className='listgroup'>
        
        <PageActions backButton={{link: '/coach', label:"Home"}} actionButtons={[{action: createProduct, icon:<Add />, label: "Create Assessment", disabled: false }]} />
        <div className="listDiv">
          <Box color="primary" className="list-header-row">
            <Typography
              component="div"
              className="list-row-item item1"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Assessment
            </Typography>

            <Typography
              component="div"
              className="list-row-item item2"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Type
            </Typography>
            <Typography
              component="div"
              className="list-row-item item3"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Description
            </Typography>
          </Box>
          {loading ? (
            <div className="loading-cont">
              <CircularProgress />
            </div>
          ) : (
            <div className="list-body">
              {assessments.length > 0 ? (
                assessments.map(assessment =>
                    <div onClick={() => viewAssessment(assessment)} key={assessment.id} className="list-row">
                      <Typography
                        variant="button"
                        className="list-row-item item1"
                        component="div"
                      >
                        {assessment.name}
                      </Typography>

                      <Typography variant="button" className="list-row-item item2" component="div">
                        {assessment.category}
                      </Typography>

                      <Typography variant="button" className="list-row-item item3" component="div">
                        {assessment.description}
                      </Typography>
                      
                      <div className="list-row-item item5">
                      <PopupMenu 
                        menuItems={[
                          {text: 'Delete', action: () => deleteAssessment(assessment), icon: <DeleteOutlined />},
                        ]} 
                        icon={<MoreVert />} 
                        popoverId={`tempalte-list-${assessment.id}`} 
                        />
                      </div>
                      
                    </div>
                  
                )
              ) : (
                <div
                  style={{
                    gridRow: '1/-1',
                    gridColumn: '1/-1',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    color="InactiveCaptionText"
                    sx={{ width: '100%' }}
                    component="span"
                    variant="h4"
                  >
                    No assessments Found
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}