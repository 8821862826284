import {
  SessionBaseInterface,
  SessionDetailInterface,
  SessionFeedbackRequest,
  SessionInterface,
} from '../../tsUtils';
import { WorkoutActions } from '../workouts/types';

export const actionTypes = {
  // List Session
  GET_SESSIONS: '[Sessions] Get Session',
  GET_SESSIONS_SUCCESS: '[Sessions] Get Session Success',
  GET_SESSIONS_FAIL: '[Sessions] Get Session Failure',
  // Update Session
  UPDATE_SESSION_REQUEST: '[Sessions] Update Session Request',
  UPDATE_SESSION_SUCCESS: '[Sessions] Update Session Success',
  UPDATE_SESSION_FAIL: '[Sessions] Update Session Failure',

  UPDATE_SESSION_FEEDBACK_REQUEST: '[Sessions] Update Session Feedback Request',
  UPDATE_SESSION_FEEDBACK_SUCCESS: '[Sessions] Update Session Feedback Success',
  UPDATE_SESSION_FEEDBACK_FAIL: '[Sessions] Update Session Feedback Failure',

  CREATE_SESSION_REQUEST: '[Sessions] Create Session Request',
  CREATE_SESSION_SUCCESS: '[Sessions] Create Session Success',
  CREATE_SESSION_FAIL: '[Sessions] Create Session Failure',

  CLEAR_SESSION: '[Sessions] Clear Session',
  CLEAR_SESSION_SUCCESS: '[Sessions] Clear Session Success',
  CLEAR_SESSION_FAIL: '[Sessions] Clear Session Failure',

  DELETE_SESSION_REQUEST: '[Sessions] Delete Session Request',
  DELETE_SESSION_SUCCESS: '[Sessions] Delete Session Success',
  DELETE_SESSION_FAIL: '[Sessions] Delete Session Fail',

  RENAME_SESSION_REQUEST: '[Sessions] Rename Session Request',
  RENAME_SESSION_SUCCESS: '[Sessions] Rename Session Success',
  RENAME_SESSION_FAIL: '[Sessions] Rename Session Fail',

  COPY_SESSION_REQUEST: '[Sessions] Copy Session Request',
  COPY_SESSION_SUCCESS: '[Sessions] Copy Session Success',
  COPY_SESSION_FAIL: '[Sessions] Copy Session Fail',

  REPEAT_WORKOUT_REQUEST: '[Session] Repeat Workout Request',

  RENAME_SESSION_SECTION: '[Sessions] Rename Session Section',
  RENAME_SESSION_SECTION_SUCCESS: '[Sessions] Rename Session Section Success',
  
  ADD_SESSION_SECTION: '[Sessions] Add Session Section',
  ADD_SESSION_SECTION_SUCCESS: '[Sessions] Add Session Section Success',

  DELETE_SESSION_SECTION: '[Sessions] Delete Session Section',
  DELETE_SESSION_SECTION_SUCCESS: '[Sessions] Delete Session Section Success',
};

export const sessionActions = {
  getSessions: (query: string) => {
    return {
      type: actionTypes.GET_SESSIONS,
      query,
    };
  },
  updateSession: (session: SessionInterface) => {
    return {
      type: actionTypes.UPDATE_SESSION_REQUEST,
      session,
    };
  },
  updateSessionFeedback: (data: SessionFeedbackRequest) => {
    return {
      type: actionTypes.UPDATE_SESSION_FEEDBACK_REQUEST,
      data,
    };
  },
  createSession: (session: SessionBaseInterface) => {
    return {
      type: actionTypes.CREATE_SESSION_REQUEST,
      session,
    };
  },
  deleteSession: (
    day: Day,
    week: number,
    config: {
      workout_id: number;
      session_id: number;
      date_time: string;
    },
  ) => {
    return {
      type: actionTypes.DELETE_SESSION_REQUEST,
      day,
      week,
      config,
    };
  },
  renameSession: (session_id: number, name: string) => {
    return {
      type: actionTypes.RENAME_SESSION_REQUEST,
      session_id,
      name,
    };
  },
  copySession: (session_id: number) => {
    return {
      type: actionTypes.COPY_SESSION_REQUEST,
      session_id,
    };
  },
  repeatWorkout: (data: {
    day: Day;
    week: number;
    program: number;
    workout: number;
    date_time: string;
  }) => {
    return {
      type: actionTypes.REPEAT_WORKOUT_REQUEST,
      data,
    };
  },
  renameSessionSection: (session_id: number, section_index: number, name: string) => {
    return {
      type: actionTypes.RENAME_SESSION_SECTION,
      session_id,
      section_index,
      name,
    };
  },
  addSessionSection: (session_id: number, section_index: number, name: string) => {
    return {
      type: actionTypes.ADD_SESSION_SECTION,
      session_id,
      section_index,
      name,
    };
  },
  deleteSessionSection: (session_id: number, section_index: number) => {
    return {
      type: actionTypes.DELETE_SESSION_SECTION,
      session_id,
      section_index,
    };
  },
};
