import React from 'react';
import { LinearProgress, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Add } from '@mui/icons-material';
import { PageActions } from '../../common/PageActions';
import { clientSelectors } from '../../+store/clients/reducers';
import { assessmentActions } from '../../+store/assessments/actionTypes';
import { Assessment, AssessmentResult } from '../../tsUtils/assessmentTypes';
import AssessmentUpdate from './forms/AssessmentUpdate';
import { openAssessmentResultCreate } from '../../store/app/actions';

const AssessmentStyle = {
  pageStyle: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    padding: '0px 10px',
  },
  
}

export default function AssessmentDetailPage() {
  const selectedAssessment = useSelector((state: RootState) => state.assessments.selected_assessment);
  const clients = useSelector((state: RootState) => clientSelectors.selectAll(state));
  const loading = useSelector((state: RootState) => state.assessments.loading);
  const dispatch = useDispatch();

  const saveAssessment = (formData: Assessment) => {
    dispatch(assessmentActions.updateAssessment( formData));
  }

  const saveResult = (formData: AssessmentResult) => dispatch(assessmentActions.createAssessmentResult(formData));

  return (
    <div style={AssessmentStyle.pageStyle as React.CSSProperties} >
      <PageActions 
        backButton={{link: '/coach/assessments', label:"Back"}} 
        actionButtons={[
          {action: () => {
            const assessmentResult = new AssessmentResult({
              assessment: selectedAssessment?.id,
              results: selectedAssessment?.assessment_config,
              assessment_date: new Date().toLocaleDateString(),
              comment: "",
            })
            return dispatch(openAssessmentResultCreate({assessmentResult, saveResult, assessmentName: selectedAssessment?.name}));  
          }, icon:<Add />, label: "Add Result", disabled: false }
        ]} 
      />
      {selectedAssessment && !loading ? (
        <AssessmentUpdate clients={clients} saveAssessment={saveAssessment} selectedAssessment={selectedAssessment} />
      ): (
        <LinearProgress sx={{ width: '100%' }} />
      ) 
    }
    </div>
  );
}