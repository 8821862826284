import React from 'react';
import { StyledInput } from '../../../common/StyledInput';
import { Autocomplete, Avatar, Button, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Typography } from '@mui/material';
import { Add, Delete, Info, InfoOutlined, Save, SaveOutlined } from '@mui/icons-material';
import { assessmentConfigDefaults, AssessmentConfigItem, assessmentConfigTypes } from '../../../tsUtils/assessmentTypes';
import { Assessment } from '../../../tsUtils/assessmentTypes';
import { InputGroup, ListUserInterface, User } from '../../../tsUtils';
import CardInputs from '../../programs/components/CardInputs';
import ExerciseSearch from '../../programs/components/ExerciseSearch';
import { selectinput_types, selectInputTypesAsObject } from '../../../+store/definitions/inputs/reducers';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { StyledSelect } from '../../../common/StyledSelect';

interface Props {
  selectedAssessment: Assessment;
  saveAssessment: (formData: Assessment) => void;
  clients: ListUserInterface[];
}
const AssessmentStyle = {
  formStyle: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginBottom: '10px',
  },
  itemContainer: {
    display: 'flex',
    gap: '10px',
    width: '100%',
    margin: '10px 0 0 0',
  },
  inputStyle: {
    width: '250px'
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    margin: '10px 60px',
  },
  assessmentItemHeader: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}

export default function AssessmentUpdate(props: Props) {
  const [name, setName] = React.useState(props.selectedAssessment.name);
  const [description, setDescription] = React.useState(props.selectedAssessment.description);
  const [category, setCategory] = React.useState(props.selectedAssessment.category);
  const [assessment_config, setAssessmentConfig] = React.useState(props.selectedAssessment.assessment_config);
  const [assessmentClients, setAssessmentClients] = React.useState(props.selectedAssessment.clients);
  const inputTypes = useSelector((state: RootState) => selectinput_types(state));

  const removeAssessmentItem = (index: number) => {
    const newConfig = [...assessment_config];
    newConfig.splice(index, 1);
    setAssessmentConfig(newConfig);
  }

  const handleSelectClients = (newValue: ListUserInterface[]) => {
    setAssessmentClients(newValue);
  }

  const emitSave = () => {
    const formData = new Assessment({
      id: props.selectedAssessment.id,
      name,
      description,
      category,
      assessment_config,
      creator: props.selectedAssessment.creator,
      clients: assessmentClients,
    });
    props.saveAssessment(formData);
  }

  return (
    <>
      <div style={AssessmentStyle.formStyle as React.CSSProperties} className="card">
        <Typography variant="h6">Details</Typography>
        <StyledInput height="40px" textalign='left' label="Name" value={name} onChange={(event) => setName(event.currentTarget.value)} />
        <StyledInput height="40px" textalign='left' rows={4} label="Description" multiline value={description} onChange={(event) => setDescription(event.currentTarget.value)} />
        <StyledInput height="40px" textalign='left' label="Type" value={category} onChange={(event) => setCategory(event.currentTarget.value)} />
        <Autocomplete
          multiple
          value={assessmentClients}
          getOptionLabel={(client) => `${client!.first_name} ${client!.last_name}`}
          options={props.clients}
          onChange={(event, newValue) => handleSelectClients(newValue!)}
          renderInput={(params) => <StyledInput textalign="left" height='40px' {...params} label="Clients" />}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={`${option!.first_name} ${option!.last_name}`}
                  {...tagProps}
                  color="info"
                />
              );
            })
          }
        />
      </div>
      <div className="card">
        <Typography variant="h6">Assessments</Typography>
        { inputTypes &&
          assessment_config.map((item, index) => {
            return (
              <div style={AssessmentStyle.itemContainer} key={'config' + index}>
                
                <div style={{ flex: 1, ...AssessmentStyle.formStyle } as React.CSSProperties} >
                <div style={AssessmentStyle.assessmentItemHeader}>
                  <Avatar sx={{ height: '30px', width: '30px', fontSize: 'medium', bgcolor: 'primary.main' }}>{index + 1}</Avatar>
                  <ExerciseSearch
                    selectedExercise={item.exercise}
                    cardIndex={index}
                    onSelectExercise={exercise => {
                      const newConfig = [...assessment_config];
                      newConfig[index].exercise = exercise;
                      setAssessmentConfig(newConfig);
                    }}
                  />
                  <Delete
                    color="error"
                    fontSize="medium"
                    onClick={() => removeAssessmentItem(index)}
                  />
                  </div>
                  <div style={AssessmentStyle.formStyle as React.CSSProperties} >
                    <StyledSelect 
                      value={item.assessment_type}
                      height="30px"
                      label="Type"
                      onChange={(value) => {
                        const newConfig = [...assessment_config];
                        newConfig[index].assessment_type = value.target.value as string;
                        newConfig[index].inputs = assessmentConfigDefaults[value.target.value as keyof typeof assessmentConfigDefaults].inputs;
                        setAssessmentConfig(newConfig);
                      }} 
                    >
                      {assessmentConfigTypes.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                      ))}
                    </StyledSelect>
                    <Typography variant="caption" color="info" fontSize="medium">
                      <InfoOutlined /> {assessmentConfigDefaults[item.assessment_type as keyof typeof assessmentConfigDefaults].instructions}
                    </Typography>
                  </div>
                  <CardInputs 
                    disableKeys={true}
                    cardIndex={index} 
                    onInputChange={(inputs: InputGroup) => {
                      const newConfig = [...assessment_config];
                      newConfig[index].inputs = inputs;
                      setAssessmentConfig(newConfig);
                    }} 
                    inputOptions={inputTypes} 
                    inputs={item.inputs}
                    client={0}
                  />

                </div>

              </div>
            )
          })
        }
        <Button
          variant="outlined"
          onClick={() => setAssessmentConfig([...assessment_config, new AssessmentConfigItem()])}>
          <Add /> Add Assessment
        </Button>
      </div>
      <div style={AssessmentStyle.formFooter} >
        <Button onClick={emitSave} variant="outlined">
          <SaveOutlined /> Save 
        </Button>
      </div>
      
      </>
  )
}