import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './style/App.scss';
import './style/Layout.scss';
import ReactDom from 'react-dom/client';
import store from './store/store';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#A4DFD7',
      main: '#15998E',
      dark: '#07383F',
      contrastText: '#EFEFEF',
    },
    info: {
      main: '#07383F',
    },
    secondary: {
      main: '#A4DFD7',
    },
    warning: {
      main: '#E34404',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export const history = createBrowserHistory();
export function forwardTo(location: any) {
  return history.push(location);
}
const container = document.getElementById('root');

const root = ReactDom.createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
          <App history={history} />
      </ThemeProvider>
    </LocalizationProvider>
  </Provider>,
);
//<LocalizationProvider dateAdapter={MuiPickersAdapterContext}></LocalizationProvider>;
//:workout_id?/:session_id?
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
