import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { WorkoutDetailInterface } from '../tsUtils';
import PreviewExerciseCard from './PreviewExerciseCard';
import parse from 'html-react-parser';

type Props = {
  workout: WorkoutDetailInterface;
};
const wrap: 'wrap' | 'nowrap' = 'wrap';

const ContainerStyle = {
  margin: '0px 15px',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  gap: '10px'
};
const ItemStyle = {
  minHeight: 100,
  width: '100%',
  margin: '0px auto',
  backgroundColor: 'white',
  border: '1px solid #bbb',
  borderRadius: '6px',
};

function PreviewWorkout(props: Props) {
  return (
    <div className="print-workout-cont">
      <div className="print-workout-details">
        <Typography
          style={{
            margin: '15px',

            textAlign: 'center',
            background: '#07383F',
            color: 'white',
          }}
          component="div"
          variant="button"
        >
          {props.workout.name}
        </Typography>
        <div style={ContainerStyle}>
          <div style={ItemStyle}>
            <Typography
              variant="button"
              sx={{ textAlign: 'center', width: '100%', fontSize: '1em' }}
              component="div"
            >
              Warm-up
            </Typography>
            <div id="workout-warm-up" className="text-edit">
            {props.workout.warmups.map((warmup: any) => (
                <div key={warmup.id}>
                  <h3>{warmup.name}</h3>
                  <p>{warmup.description}</p>
                </div>
              ))}
            </div>
          </div>

          <div style={ItemStyle}>
            <Typography
              variant="button"
              sx={{ textAlign: 'center', width: '100%', fontSize: '1em' }}
              component="div"
            >
              Cool-down
            </Typography>
            <div id="workout-cool-down" className="text-edit">
            {props.workout.cooldowns.map((cooldown: any) => (
                <div key={cooldown.id}>
                  <h3>{cooldown.name}</h3>
                  <p>{cooldown.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {props.workout.sessions.map((session, index) => {
        const cardPairs = [];
        for (let index = 0; index <= session.exercise_cards.length - 1; ) {
          let pair = [];
          pair.push(session.exercise_cards[index]);
          if (session.exercise_cards.length - 1 > index) {
            pair.push(session.exercise_cards[index + 1]);
          }
          cardPairs.push(pair);
          index += 2;
        }
        return (
          <div key={session.id} className="print-session-cont">
            <Typography
              style={{
                textAlign: 'center',
                background: '#15998E',
                color: 'white',
              }}
              component="div"
              variant="button"
            >
              Session {index + 1}
            </Typography>

            {cardPairs.map((pair, index) => (
              <div key={index + 'pair'} className="print-cards-cont">
                {pair.map(card => (
                  <PreviewExerciseCard key={card.id} card={card} />
                ))}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

export default PreviewWorkout;
