import {
  ExerciseCardClass,
  ProgramConfigClass,
  ProgramDetailInterface,
  ProgramInterface,
  SessionInterface,
  WorkoutDetailInterface,
  WorkoutInterface,
} from '../../tsUtils';
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import { workoutSelectors } from '../workouts/reducers';
import { sessionSelectors, sessionsFail } from '../sessions/reducers';
import { exerciseCardSelectors } from '../exerciseCards/reducers';
import { PlaceRounded } from '@mui/icons-material';
import { ClientInputsInterface } from '../../tsUtils/clientInputsTypes';
export interface ProgramState extends EntityState<ProgramInterface> {
  selectedId?: number;
  loading: boolean;
  loaded: boolean;
  error: any;
  selectedProgram: ProgramInterface | null;
  message?: string;
  pagination?: {
    count: number;
    next: string;
    previous: string;
  };
}

const programAdapter = createEntityAdapter<ProgramInterface>({});

export const programInitialState: ProgramState = programAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  selectedProgram: null,
});

export const programSlice = createSlice({
  name: 'programs',
  initialState: programInitialState,
  reducers: {
    programsLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    programsLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    programsFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getProgramsSuccess(state, action) {
      programAdapter.addMany(state, action.payload.results);
      state.pagination = {
        count: action.payload.count,
        next: action.payload.next? '?' + action.payload.next?.split('?')[1] : '',
        previous: action.payload.previous? '?' + action.payload.previous?.split('?')[1] : '',
      };
    },
    clearSelectedProgram(state) {
      state.selectedProgram = null;
    },
    getProgramSuccess(state, action) {
      state.selectedProgram = action.payload;
    },
    setSelectedById(state, action) {
      const program = state.entities[action.payload]
      if(program) {
        state.selectedId = program.id
        state.selectedProgram = program
      }
    },
    createProgramSuccess(state, action) {
      state.selectedProgram = action.payload;
      programAdapter.upsertOne(state, action.payload);
    },
    updateProgramMessage(state, action) {
      state.message = action.payload;
    },
    removeProgramMessage(state) {
      let newState = state;
      delete newState.message;
      state = newState;
    },
    createFreeProgramSuccess(state, action) {
      state.selectedProgram = {
        ...action.payload,
        id: 1,
      };
    },
    deleteProgramSuccess(state, action) {
      programAdapter.removeOne(state, action.payload);
    },
    updateProgramSuccess(state, action) {
      state.selectedProgram = {
        ...action.payload,
        program_config: { ...state.selectedProgram?.program_config },
      };
    },
    updateProgramConfigSuccess(
      state,
      {
        payload,
      }: {
        type: string;
        payload: {
          day: Day;
          week: number;
          session_config: { workout_id: number; session_id: number; date_time: string };
        };
      },
    ) {
      if (state.selectedProgram) {
        state.selectedProgram.program_config.plan[payload.week][payload.day] = [
          ...state.selectedProgram?.program_config.plan[payload.week][payload.day],
          {
            ...payload.session_config,
          },
        ];
        state.selectedProgram.program_config.plan[payload.week][payload.day].sort(
          (a, b) => new Date(a.date_time).getTime() - new Date(b.date_time).getTime(),
        );
      }
    },
    removeProgramConfigSessionSuccess(
      state,
      {
        payload,
      }: {
        type: string;
        payload: {
          day: Day;
          week: number;
          session_id: number;
        };
      },
    ) {
      if (state.selectedProgram) {
        state.selectedProgram.program_config.plan[payload.week][payload.day] =
          state.selectedProgram?.program_config.plan[payload.week][payload.day].filter(
            session => session.session_id !== payload.session_id,
          );
      }
    },
    addProgramWeekSuccess(
      state,
      {
        payload,
      }: {
        type: string;
        payload: number;
      },
    ) {
      if (state.selectedProgram) {
        const config = { ...state.selectedProgram.program_config };

        state.selectedProgram.program_config = {
          no_weeks: config.no_weeks + 1,
          plan: [
            ...config.plan,
            {
              0: [],
              1: [],
              2: [],
              3: [],
              4: [],
              5: [],
              6: [],
            },
          ],
        };
      }
    },
    addWorkoutSuccess(state, action) {
      if (state.selectedProgram) {
        state.selectedProgram.workouts = [
          ...state.selectedProgram.workouts,
          action.payload.workout,
        ];
      }
    },
    removeProgramWorkout(state, action: { payload: { workout_id: number; programId: number } }) {
      const program = state.entities[action.payload.programId];

      if (program) {
        const workouts: { order: number; id: number }[] = program?.workouts.filter(
          workout => workout.id !== action.payload.workout_id,
        );
        programAdapter.upsertOne(state, {
          ...program,
          workouts,
        });
      } else {
        return state;
      }
    },
    removeProgramWeekSuccess(state, action) {
      state.selectedProgram = {
        ...state.selectedProgram!,
        program_config: { ...action.payload },
      };
      programAdapter.upsertOne(state, {
        ...state.selectedProgram,
        program_config: { ...action.payload },
      });
    },
    setSelectedProgramId(state, action) {
      state.selectedId = action.payload;
    },
  },
});

export const selectProgramDetails = createSelector(
  [
    (state: RootState) => state.programs.selectedProgram,
    (state: RootState) => workoutSelectors.selectAll(state),
    (state: RootState) => sessionSelectors.selectAll(state),
    (state: RootState) => exerciseCardSelectors.selectAll(state),
    (state: RootState, programId: number) => programId,
  ],
  (program, workouts, allSessions, allCards, programId) => {
    const details = workouts.reduce(
      (dets, workout) => {
        if (workout.program === programId) {
          dets.workouts.push(workout);
          allSessions
            .filter(session => session.workout === workout.id)
            .forEach(session => {
              dets.sessions.push(session);
              allCards
                .filter(card => card.session === session.id)
                .forEach(card => dets.exercise_cards.push(card));
            });
        }
        return dets;
      },
      { workouts: [], sessions: [], exercise_cards: [] } as {
        workouts: WorkoutInterface[];
        sessions: SessionInterface[];
        exercise_cards: ExerciseCardClass[];
      },
    );
    return {
      ...details,
      program_config: program?.program_config ?? new ProgramConfigClass(),
    };
  },
);
export const selectProgramConfig = createSelector(
  [(state: RootState) => state.programs.selectedProgram],
  program => {
    if (program) return { ...program.program_config };
  },
);
export const selectProgramClientDetails = createSelector(
  [
    (state: RootState) => workoutSelectors.selectAll(state),
    (state: RootState) => sessionSelectors.selectAll(state),
    (state: RootState) => exerciseCardSelectors.selectAll(state),
    (state: RootState) => state.auth.user?.id,
    (state: RootState, programId: number) => programId,
  ],
  (workouts, allSessions, allCards, userId, programId) => {
    const inputs = workouts.reduce((client_inputs, workout) => {
      if (workout.program === programId) {
        allSessions
          .filter(session => session.workout === workout.id)
          .forEach(session => {
            allCards
              .filter(card => card.session === session.id)
          });
      }
      return client_inputs;
    }, [] as ClientInputsInterface[]);
    return inputs;
  },
);
export const selectDetailedProgram = createSelector(
  [
    (state: RootState) => state.programs.selectedProgram,
    (state: RootState) => workoutSelectors.selectAll(state),
    (state: RootState) => sessionSelectors.selectAll(state),
    (state: RootState) => exerciseCardSelectors.selectAll(state),
    (state: RootState, programId: number) => programId,
  ],
  (program, workouts, allSessions, allCards, programId) => {
    let programDetails = { ...program };
    const details = workouts.reduce((dets, workout) => {
      if (workout.program === programId) {
        let sessions = allSessions
          .filter(session => session.workout === workout.id)
          .map(session => {
            let cards = allCards.filter(card => card.session === session.id);
            return {
              ...session,
              exercise_cards: cards ?? [],
            };
          });
        dets.push({
          ...workout,
          sessions: sessions ?? [],
        });
      }
      return dets;
    }, [] as WorkoutDetailInterface[]);

    programDetails.workouts = details;
    return programDetails as ProgramDetailInterface;
  },
);

export const {
  addProgramWeekSuccess,
  addWorkoutSuccess,
  clearSelectedProgram,
  createProgramSuccess,
  deleteProgramSuccess,
  getProgramSuccess,
  getProgramsSuccess,
  programsFail,
  programsLoaded,
  programsLoading,
  removeProgramConfigSessionSuccess,
  removeProgramWeekSuccess,
  removeProgramWorkout,
  setSelectedById,
  setSelectedProgramId,
  updateProgramConfigSuccess,
  updateProgramMessage,
  updateProgramSuccess,
} = programSlice.actions;

export const programSelectors = programAdapter.getSelectors<RootState>(state => state.programs);
