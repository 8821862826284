import React, { useEffect } from 'react';
import '../style/App.scss';
import '../style/Layout.scss';
import SideBar from '../common/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../+store/clients/actionTypes';
import { getDefinitions } from '../+store/definitions/inputs/actionTypes';
import { programActions } from '../+store/programs/actionTypes';
import { Link, Navigate } from 'react-router-dom';
import { getEvents } from '../+store/events/actionTypes';
import { toggleSidebar } from '../store/app/actions';
import { Outlet } from 'react-router-dom';
import { RootState } from '../store/store';
import { Button, CircularProgress } from '@mui/material';
import '../style/Unauth/unauthMain.scss';
import { templateActions } from '../+store/templates/actionTypes';
import { AccountType } from '../tsUtils';
import {CalendarToday, ContentCopy, ContentPaste,TimerOutlined, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, PeopleOutline, Refresh} from '@mui/icons-material';
import { createPortal } from 'react-dom';
import Calculator from '../common/Calculator';
import Saved from '../common/Saved';

function CoachApp(props: any) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const loaded = useSelector((state: RootState) => state.auth.loaded);
  const sideBar = useSelector((state: RootState) => state.app.sideBar);
  useEffect(() => {
    dispatch(getEvents(''));
    dispatch(getDefinitions());
    dispatch(getClients(''));
    dispatch(templateActions.getTemplatesRequest(''));
    dispatch(programActions.getPrograms(''));
  }, [dispatch]);

  const sideBarMenu = [
    {
      link: '/coach/schedule',
      icon: <CalendarToday />,
      title: 'Schedule',
    },
    {
      link: '/coach/programs',
      icon: <ContentPaste />,
      title: 'Programs',
    },
    {
      link: '/coach/templates',
      icon: <ContentCopy />,
      title: 'Templates',
    },
    {
      link: '/coach/assessments',
      icon: <TimerOutlined />,
      title: 'Assessments',
      staff_only: true,
    },
    {
      link: '/coach/clients',
      icon: <PeopleOutline />,
      title: 'Clients',
    },

  ];
  const topBarMenu = [
    {
      link: '/coach/schedule',
      icon: 'bi bi-calendar',
      title: 'Schedule',
    },
    {
      link: '/coach/programs',
      icon: 'bi bi-clipboard',
      title: 'Programs',
    },
    {
      link: '/coach/clients',
      icon: 'bi bi-people',
      title: 'Clients',
    },
  ];

  return loaded ? (
    user && user.account_type !== AccountType.Client ? (
      <div className="layoutMainCoach">
        <aside
          style={{ gridColumn: '1/2', display: sideBar ? 'block' : 'none' }}
          className="layoutSidebarCoach"
        >
          <SideBar open={sideBar} menu1={sideBarMenu} ></SideBar>
        </aside>

        <main style={{ gridColumn: sideBar ? '2/3' : '1/3' }} className="main">
          <div className='main-head'>
            <div>{ sideBar ? 
            <KeyboardDoubleArrowLeft onClick={() => dispatch(toggleSidebar())} className='main-head__icon dark-text' /> :
            <KeyboardDoubleArrowRight onClick={() => dispatch(toggleSidebar())} className='main-head__icon dark-text' />
            }</div>
            
            <div><img style={{ width: '200px' }} src="/CleverCoachTech-logos_dark.png" alt="logo" /></div>
            <div> 
              {isMobile && (
                <Button color='info' onClick={() => window.location.reload()}>
                  <Refresh fontSize='large' />
                </Button>
              )}
            </div>
          </div>
          <Outlet />
          {createPortal(<Calculator />, document.getElementById('calculator') as HTMLElement)} 
        </main>
      </div>
    ) : user ? (
      <Navigate
        to={{
          pathname: '/client/programs',
          //state: { from: location }
        }}
      />
    ) : (
      <Navigate
        to={{
          pathname: '/',
          //state: { from: location }
        }}
      />
    )
  ) : (
    <div className="unauthmain">
      <CircularProgress />
    </div>
  );
}

export default CoachApp;
