import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import {getProgram, clearProgram} from '../../../store/programs/actions';
import '../../../style/Coach/Program.scss';
import '../../../style/Coach/Workout.scss';
import { programActions } from '../../../+store/programs/actionTypes';
import store, { RootState } from '../../../store/store';
import ProgramHead from './ProgramHead';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  Day,
  ProgramConfigClass,
  ProgramConfigSession,
  SessionQuestion,
  WeekConfig,
  WorkoutInterface,
} from '../../../tsUtils';
import WorkoutFormComponent from '../components/WorkoutFormComponent';
import { workoutActions } from '../../../+store/workouts/actionTypes';
import { sessionActions } from '../../../+store/sessions/actionTypes';
import {
  workoutSlice,
  getSelectedWorkout,
} from '../../../+store/workouts/reducers';
import { sessionSlice, selectCurrentSession } from '../../../+store/sessions/reducers';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RenameDialog from '../dialogs/RenameDialog';
import WorkoutDialog from '../dialogs/WorkoutDialog';
import RepeatWorkoutDialog from '../dialogs/RepeatWorkoutDialog';
import { ContentCopyOutlined, DeleteOutlined, EditOutlined, LocalPrintshopOutlined, MoreVert, SaveOutlined } from '@mui/icons-material';
import { clearSelectedProgram } from '../../../+store/programs/reducers';
import { updateCurrentClient } from '../../../+store/exerciseCards/reducers';
import SessionForm from '../components/SessionForm';
import ConfirmDeleteWeek from '../dialogs/ConfirmDeleteWeek';
import { clientSelectors } from '../../../+store/clients/reducers';
import moment from 'moment';
import { PageActions } from '../../../common/PageActions';
import NewTemplateForm from '../dialogs/NewTemplate';
import ConfirmDeleteSession from '../dialogs/ConfirmDeleteSession';
import PopupMenu from '../../../common/PopupMenu';
import { StyledInput } from '../../../common/StyledInput';
import { StyledSelect } from '../../../common/StyledSelect';

// import {editProgram, deleteWorkout} from '../../services/API';

interface Props {
  history: any;
  client: boolean;
}

function ProgramComponent(props: Props) {
  // const programId = Number(props.match.params.programId);
  const { programId, weekNo, workout_id, session_id } = useParams();
  // useParams<typeof props.match>();
  const clients = useSelector((state: RootState) => clientSelectors.selectAll(state));
  const program = useSelector((state: RootState) => state.programs.selectedProgram);
  const workout = useSelector((state: RootState) => getSelectedWorkout(state));
  const session = useSelector((state: RootState) => selectCurrentSession(state));
  const [currentWeek, setCurrentWeek] = useState(Number(weekNo));
  const [config, setConfig] = useState<ProgramConfigClass | null>(null);
  const programLoaded = useSelector((state: RootState) => state.programs.loaded);
  const programLoading = useSelector((state: RootState) => state.programs.loading);
  const [openWorkout, setOpenRenameWorkout] = React.useState(false);
  const [updatingWorkout, setUpdatingWorkout] = React.useState<WorkoutInterface | null>(null);
  const [updatingQuestions, setUpdatingQuestions] = React.useState<SessionQuestion[] | null>(null);
  const [openRemoveSession, setOpenRemoveSession] = React.useState(false);
  const [deleteingSession, setDeleteingSession] = React.useState<{day: Day,
    week: number,
    config: { session_id: number; workout_id: number; date_time: string }} | null>(null)
  const [selectedClient, setSelectedClient] = React.useState(0);
  const [deletingWeek, setDeleteingWeek] = React.useState<{
    week: WeekConfig;
    weekNo: number;
  } | null>(null);
  const [openDeleteWeek, setOpenDeleteWeek] = React.useState<boolean>(false);
  const userType = useSelector((state: RootState) => state.auth.user?.account_type);
  const [selectedValue, setSelectedValue] = useState<{
    session_id: number;
    workout_id: number;
  } | null>();
  const [selectedColValue, setSelectedColVal] = useState<{
    day: Day;
    week: number;
  }>({ day: Day.Sunday, week: Number(weekNo) });
  const [openCreateWorkout, setOpenCreateWorkout] = useState<boolean>(false);
  const [openRepeatWorkout, setOpenRepeatWorkout] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [openTemplate, setOpenTemplate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.client) {
      dispatch(programActions.getProgramWorkouts(Number(programId)));
    }
    if (props.client) {
      dispatch(programActions.getClientProgramWorkouts(Number(programId)));
    }
    if (workout_id && session_id) {
      dispatch(sessionSlice.actions.setSelectedSessionId(Number(session_id)));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(Number(workout_id)));
      setSelectedValue({ session_id: Number(session_id), workout_id: Number(workout_id) });
    }

    return () => {
      dispatch(clearSelectedProgram());
    };
  }, []);
  useEffect(() => {
    if (program && program.program_config) {
      setConfig(program.program_config);
    }
  }, [program]);

  const updateWorkoutName = (value: string) => {
    if (workout_id) {
      dispatch(workoutActions.renameWorkout(Number(workout_id), value));
    }
    setOpenRenameWorkout(false);
  };
  const confirmRemoveSession = (day: Day,
    week: number,
    config: { session_id: number; workout_id: number; date_time: string }) => {
    setDeleteingSession({day, week, config})
    setOpenRemoveSession(true);
  }

  const removeSession = (
    day: Day,
    week: number,
    config: { session_id: number; workout_id: number; date_time: string },
  ) => {
    dispatch(sessionActions.deleteSession(day, week, config));
    setOpenRemoveSession(false);
    handleChangeSession();
  };

  function saveProgram() {
    if (programId) {
      dispatch(programActions.updateProgramDetails(Number(programId)));
    }
  }
  const handleChangeSession = (value?: ProgramConfigSession, noReplace?: boolean, weekNo?:number ) => {
    if (value) {
      dispatch(sessionSlice.actions.setSelectedSessionId(value.session_id));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(value.workout_id));
      setSelectedValue({ session_id: value.session_id, workout_id: value.workout_id });
      noReplace ??
        navigate(
          `/coach/programs/${programId}/${currentWeek}/${value.workout_id}/${value.session_id}`,
          { replace: true },
        );
    } else {
      dispatch(sessionSlice.actions.setSelectedSessionId(null));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(null));
      setSelectedValue(null);
      navigate(`/coach/programs/${programId}/${weekNo !== undefined ? weekNo : currentWeek}/`, { replace: true });
    }
  };
  const addWeekToConfig = () => {
    dispatch(programActions.addProgramWeek(Number(programId)));
  };

  const deleteWeek = (weekIndex: number) => {
    if (weekIndex === Number(weekNo)) {
      navigate(`/coach/programs/${program?.id}/${weekIndex - 1}/`, { replace: true });
      setCurrentWeek(weekIndex - 1);
    } else if (Number(weekNo) > weekIndex) {
      navigate(`/coach/programs/${program?.id}/${0}/`, { replace: true });
      setCurrentWeek(0);
    }
    dispatch(programActions.deleteProgramWeek(Number(programId), weekIndex));
  };

  const confirmRemoveWeek = (week: WeekConfig, index: number) => {
    setDeleteingWeek({ week, weekNo: index });
    setOpenDeleteWeek(true);
  };

  const handleChangeWeek = (weekNo: number) => {
    handleChangeSession(undefined, true, weekNo);
    setCurrentWeek(weekNo);
  };
  const getDate = (day: number) => {
    if (program) {
      const weekVal = currentWeek * 7;
      const newDate = new Date(program?.start);
      newDate.setDate(newDate.getDate() + (day - newDate.getDay() + weekVal));
      const dateArr = newDate.toDateString().split(' ');
      return `${dateArr[2]} / ${dateArr[1]}`;
    }
  };
  const printProgram = () => {
    saveProgram();
    navigate(`/coach/programs/${programId}/print`);
  };
  const updateWorkout = (config: { session_id: number; workout_id: number; date_time: string }) => {
    setUpdatingQuestions(store.getState().sessions?.entities[config.session_id]?.questions!);
    setUpdatingWorkout(store.getState().workouts?.entities[config.workout_id]!);
  };
  const createNewWorkout = (day: Day) => {
    setSelectedColVal({ day, week: currentWeek });
    setOpenCreateWorkout(true);
  };
  const repeatWorkout = (day: Day) => {
    setSelectedColVal({ day, week: currentWeek });
    setOpenRepeatWorkout(true);
  };
  const getWorkoutName = (id: number) => store.getState().workouts?.entities[id]?.name ?? 'unknown';
  const handleSelectClient = (id: number) => {
    setSelectedClient(id);
    dispatch(updateCurrentClient(id));
  };
  return (
    <div id="program_container" className="program-container">
      <div className="program" >
      <div className='navStuff'>
        <PageActions
          backButton={{ label: 'Back to List', link: '/coach/programs' }}
          backAction={clearSelectedProgram}
          buttonSize='small'
          actionButtons={[
            { label: 'Create Template', action: () => setOpenTemplate(true), icon: <ContentCopyOutlined /> },
            { label: 'Print', action: printProgram, icon: <LocalPrintshopOutlined /> },
          ]}
        />
      </div>
      
      {program && (
        <NewTemplateForm
          open={openTemplate}
          onClose={() => setOpenTemplate(false)}
          programId={program.id}
          programName={program.name}
        />
      )}
      {programLoaded && !programLoading && program && config ? (
        <div className="programBody">
          <ProgramHead clients={clients} program={program} />
          <FormControl
            sx={{
              borderRadius: '4px',
              bgcolor: 'background.paper',
              marginTop: '10px',
              minWidth: 200,
              border: '1px solid #ddd',
            }}
            size="small"
            variant="filled"
          >
            <StyledSelect 
                height='40px' 
                value={selectedClient} 
                backgroundcolor="#ffffff"
                onChange={(event) => handleSelectClient(event.target.value as number)} 
                label="Clients" >
                  <MenuItem key='prescribed0' value={0}>
                  Precribed
                </MenuItem>
              {program.client.map(client => (
                <MenuItem key={client.id} value={client.id}>
                  {`${client.first_name} ${client.last_name}`}
                </MenuItem>
              ))}
            </StyledSelect>
            
          </FormControl>
          <Box
            sx={{
              maxWidth: { xs: 320, sm: 480 },
              bgcolor: 'background.paper',
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '4px',
              border: '1px solid #ddd',
            }}
          >
            <Tabs
              value={currentWeek}
              onChange={(event, newValue) => handleChangeWeek(newValue)}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="Weeks"
            >
              {program.program_config.plan.map((week, index) =>
                index > 0 ? (
                  <Tab
                    key={index}
                    label={`Week ${index + 1}`}
                    iconPosition="end"
                    sx={{ minHeight: '50px' }}
                    icon={
                      <DeleteOutlined
                        color="error"
                        fontSize="small"
                        style={{ cursor: 'pointer' }}
                        onClick={event => {
                          event.stopPropagation();
                          confirmRemoveWeek(week, index);
                        }}
                      />
                    }
                  />
                ) : (
                  <Tab key={index} label={`Week ${index + 1}`} sx={{ minHeight: '50px' }} />
                ),
              )}
            </Tabs>
            <IconButton onClick={() => addWeekToConfig()} size="small" color="primary">
              <AddIcon fontSize="large"></AddIcon>
            </IconButton>
          </Box>

          <div className="program-config-cont">
            <div className="program-config">
              {Object.entries(config.plan[currentWeek]).map((day, dayIndex) => (
                <div key={`week${day[0]}`} className="config-item">
                  <div className="config-item-title">
                    <Typography component="span" variant="button">{Day[dayIndex]}</Typography>
                    <span>{getDate(dayIndex)}</span>
                  </div>
                  {day[1].map((session, index) => (
                    
                      <Button
                        fullWidth
                        onClick={() => handleChangeSession(session)}
                        variant="contained"
                        color={session.session_id === Number(session_id) ? 'warning' : 'primary'}
                        key={`${session.session_id}_${session.workout_id}`}
                        className="config-item-pill"
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '0.9em'}}
                      >
                        <div style={{textAlign: 'center' , flex: 1}}>
                        <Typography component="span" fontSize="small" variant="button">{getWorkoutName(session.workout_id)}</Typography>
                          <div>{moment(session.date_time).format('h:mm a')}</div>
                        </div>
                        <div className="config-item-pill-actions">
                          <PopupMenu
                            popoverId={`popup_edit${dayIndex}_${index}`}
                            icon={<MoreVert fontSize="small" />}
                            menuItems={[
                              {
                                text: 'Delete',
                                icon: <DeleteOutlined fontSize="small" />,
                                action: () => confirmRemoveSession(dayIndex, currentWeek, session),
                              },
                              {
                                text: 'Edit',
                                icon: <EditOutlined fontSize="small" />,
                                action: () => updateWorkout(session),
                              },
                            ]}
                          />
                        </div>
                      </Button>
                  ))}
                  <PopupMenu
                    popoverId={`popup_new${dayIndex}`}
                    icon={<AddIcon fontSize="small" />}
                    triggerStyle={{ 
                      color: '#888', 
                      background:'white', 
                      height: '30px', 
                      width: '30px', 
                      fontSize: '1.5em', 
                      cursor: 'pointer', 
                      borderRadius: '50%', 
                      border: '1px solid #ccc' 
                    }}
                    menuItems={[
                      {
                        text: 'New Workout',
                        icon: <AddIcon fontSize="small" />,
                        action: () => createNewWorkout(dayIndex),
                      },
                      {
                        text: 'Repeat Workout',
                        icon: <ContentCopyIcon fontSize="small" />,
                        action: () => repeatWorkout(dayIndex),
                      },
                    ]}
                  />
                </div>
              ))}
            </div>
          </div>
          {workout && session && selectedValue ? (
            <div className="workout">
              <WorkoutFormComponent workout={workout} />

              <SessionForm workout_id={workout.id} session={session} />
              <RenameDialog
                open={openWorkout}
                title="Rename Workout"
                onConfirm={value => updateWorkoutName(value)}
                value={''}
                onCancel={() => setOpenRenameWorkout(false)}
              ></RenameDialog>
            </div>
          ) : (
            <Typography
              color="InactiveCaptionText"
              sx={{ width: '100%', textAlign: 'center', marginTop: '20px' }}
              component="span"
              variant="h4"
            >
              No Workout Selected
            </Typography>
          )}
          <WorkoutDialog
            title="Create Workout"
            startDate={new Date(program.start)}
            programid={program.id}
            config={selectedColValue}
            open={openCreateWorkout}
            onCancel={() => setOpenCreateWorkout(false)}
          />
          {updatingWorkout && (
            <WorkoutDialog
              workout={updatingWorkout!}
              title={`Update ${updatingWorkout?.name}`}
              startDate={program!.start}
              programid={program!.id}
              config={selectedColValue}
              open={updatingWorkout != null}
              questions={updatingQuestions!}
              onCancel={() => setUpdatingWorkout(null)}
            />
          )}
          {deletingWeek && (
            <ConfirmDeleteWeek
              open={openDeleteWeek}
              week={deletingWeek}
              onConfirm={weekIndex => deleteWeek(weekIndex)}
              onCancel={() => {
                setOpenDeleteWeek(false);
                setDeleteingWeek(null);
              }}
            />
          )}
          {deleteingSession && (
            <ConfirmDeleteSession
              open={openRemoveSession}
              session={deleteingSession}
              onConfirm={session => removeSession(session.day, session.week, session.config)}
              onCancel={() => {
                setOpenRemoveSession(false);
                setDeleteingSession(null);
              }}
            />
          )}

          <RepeatWorkoutDialog
            title="Repeat Workout"
            startDate={new Date(program.start)}
            programid={program.id}
            config={selectedColValue}
            open={openRepeatWorkout}
            onCancel={() => setOpenRepeatWorkout(false)}
          ></RepeatWorkoutDialog>
        </div>
      ) : (
        <LinearProgress sx={{ width: '100%' }} />
      )}

      <div className="program-footer">
        <div style={{ color: 'green' }}>{message}</div>
      </div>
      </div>
      
    </div>
  );
}

export default ProgramComponent;
