import { Box } from "@mui/system";
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import * as types from '../../../tsUtils';
import '../../../style/Coach/Program.scss';
import { programActions } from '../../../+store/programs/actionTypes';
import { useForm, SubmitHandler, Controller, useFormState } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ListUserInterface } from '../../../tsUtils';

interface Props {
  program: types.ProgramInterface;
  clients: ListUserInterface[];
}

interface ProgramForm {
  name: string;
  quadrennial: string;
  client: number[];
  macro: string;
  meso: string;
  viewers: number[];
  start: Date;
}

function ProgramHead(props: Props) {
  const loading = useSelector((state: RootState) => state.programs.loading);

  const user = useSelector((state: RootState) => state.auth.user);

  const { control, handleSubmit, setValue, formState } = useForm<ProgramForm>({
    defaultValues: props.program
      ? {
          name: props.program.name,
          client: props.program.client.map(client => client.id),
          viewers: props.program.viewers?.map(viewer => viewer.id),
          quadrennial: props.program.quadrennial,
          macro: props.program.macro,
          meso: props.program.meso,
          start: new Date(props.program.start),
        }
      : {
          name: '',
          client: [1],
          viewers: [],
          quadrennial: '',
          macro: '',
          meso: '',
          start: new Date(),
        },
  });

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<ProgramForm> = data => {
    dispatch(
      programActions.updateProgram({
        id: props.program?.id,
        creator: props.program!.creator.id,
        name: data.name,
        client: data.client,
        viewers: data.viewers,
        quadrennial: data.quadrennial,
        macro: data.macro,
        meso: data.meso,
        start: data.start,
        program_config: props.program.program_config,
      }),
    );
  };

  let clientList: ReactElement[] = [];
  if (props.clients !== null) {
    clientList = props.clients?.map((client, i) => (
      <MenuItem key={client.id} value={client.id}>
        {client.first_name + ' ' + client.last_name}
      </MenuItem>
    ));
  }
  clientList.push(
    <MenuItem key={user?.id} value={user?.id}>
      Myself
    </MenuItem>,
  );

  return (
    <Box className="programHead">
      <div className="program-form-cont">
        {loading ? (
          <CircularProgress />
        ) : (
          <form className="program-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="program-title">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    className="title-input"
                    id="title-input"
                    {...field}
                    title="Program Name"
                    variant="standard"
                    size="small"
                    label="Program Name"
                  />
                )}
              />
            </div>

            <div className="program-form-controls">
              <Controller
                control={control}
                name="client"
                render={({ field }) => (
                  <FormControl>
                    <InputLabel variant="filled">Clients</InputLabel>
                    <Select
                      multiple
                      {...field}
                      required
                      sx={{
                        textAlign: 'left',
                      }}
                      variant="filled"
                      size="small"
                      title="Program Client"
                      onChange={e => setValue('client', e.target.value as number[])}
                      error={Boolean(formState.errors?.viewers)}
                    >
                      {clientList}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="viewers"
                render={({ field }) => (
                  <FormControl>
                    <InputLabel variant="filled">Viewers</InputLabel>
                    <Select
                      multiple
                      variant="filled"
                      size="small"
                      title="Program Viewers"
                      sx={{
                        textAlign: 'left',
                      }}
                      {...field}
                      onChange={e => setValue('viewers', e.target.value as number[])}
                      error={Boolean(formState.errors?.viewers)}
                    >
                      {clientList}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="quadrennial"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    variant="filled"
                    label="Quadrennial Cycle"
                    title="Program Quadrennial Cycle"
                    size="small"
                  />
                )}
              />
              <Controller
                control={control}
                name="macro"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    variant="filled"
                    title="Program Macro Cycle"
                    label="Macro Cycle"
                    size="small"
                  />
                )}
              />
              <Controller
                control={control}
                name="meso"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    variant="filled"
                    title="Program meso-cycle"
                    size="small"
                    label="Meso Cycle"
                  />
                )}
              />
              <Controller
                control={control}
                name="start"
                render={({ field }) => (
                  <DatePicker
                    onChange={date => field.onChange(date)}
                    value={field.value}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Start"
                        variant="filled"
                        size="small"
                        title="Program Start Date"
                        sx={{ width: '100%' }}
                      />
                    )
                    }
                  />
                )}
              />
            </div>
            <div className="form-buttons">
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Edit
              </Button>
            </div>
          </form>
        )}
      </div>
    </Box>
  );
}

export default ProgramHead;
