import { ListUserInterface } from "./userTypes";
import { InputGroup } from "./exerciseCardTypes";
import { ExerciseBaseInterface } from "./exerciseTypes";

export const assessmentConfigTypes = [
  'One Rep Max',
  'Submaximal 1RM - Reps',
  'Submaximal 1RM - RIR',
  'Submaximal 1RM - RPE',
  'Submaximal 1RM - Velocity',
  'Velocity Curve',
  'Minimal Velocity Threshold'
]

export const assessmentConfigDefaults = {
  'One Rep Max': {
    'instructions': 'This method requires the testee to perfrom a one rep max lift.',
    'inputs': [
      {key: 11, value: ['']},
      {key: 1, value: ['']},
      {key: 6, value: ['']},
    ]
  },
  'Submaximal 1RM - Reps': {
    'instructions': 'This method requires the testee to perfrom a rep max lift for a submaximal weight. The results are used to calculate the one rep max.',
    'inputs': [
    {key: 11, value: ['']},
    {key: 1, value: ['']},
    {key: 6, value: ['']},
  ]},
  'Submaximal 1RM - RIR':{
    'instructions': 'This method requires the testee to perfrom a lift with a submaximal weight and record the reps in reserve for each set. The results are used to calculate the one rep max.',
    'inputs': [
      {key: 11, value: ['']},
    {key: 1, value: ['']},
    {key: 6, value: ['']},
    {key: 13, value: ['']},
  ]},
  'Submaximal 1RM - RPE': {
    'instructions': 'This method requires the testee to perfrom a lift with a submaximal weight and record the rating of perceived exertion for each set. The results are used to calculate the one rep max.',
    'inputs': [
      {key: 11, value: ['']},
    {key: 1, value: ['']},
    {key: 6, value: ['']},
    {key: 12, value: ['']},
  ]},
  'Submaximal 1RM - Velocity': {
    'instructions': 'Requires the user to have done a velocity curve assessment for the exercise. This method requires the testee to perfrom a lift with a submaximal weight and record the velocity for each set. The results are used to calculate the one rep max.',
    'inputs': [
      {key: 11, value: ['']},
    {key: 1, value: ['']},
    {key: 6, value: ['']},
    {key: 14, value: ['']},
  ]},
  'Velocity Curve':{ 
    'instructions': 'This method requires the testee to perfrom a series of lifts at different weights and record the velocity for each set. The results are used to calculate the velocity curve. If a maximum weight is not reached, either the users previously tested 1RM or MVT will be used. If these have not been found the RIR for each set will be used.',
    'inputs': [
    {key: 11, value: ['']},
    {key: 1, value: ['']},
    {key: 6, value: ['']},
    {key: 14, value: ['']},
    {key: 13, value: ['']},
  ]},
  'Minimal Velocity Threshold': {
    'instructions': 'This method requires the testee to perfrom a lift or series of lifts with a submaximal or maximal weight to a rep maximum. The results are used to calculate the minimal velocity threshold.',
    'inputs': [
      {key: 11, value: ['']},
    {key: 1, value: ['']},
    {key: 6, value: ['']},
    {key: 14, value: ['']},
  ]},
}

export class AssessmentConfigItem {
  exercise: ExerciseBaseInterface | null;
  assessment_type: string;
  inputs: InputGroup
  constructor(data: Partial<AssessmentConfigItem> = {}) {
    this.exercise = data.exercise ?? null;
    this.assessment_type = data.assessment_type ?? 'One Rep Max';
    this.inputs = data.inputs ?? [
      {key: 11, value: ['']},
      {key: 1, value: ['']},
      {key: 6, value: ['']},
    ];
  }
}

export class Assessment {
  id?: number;
  name: string;
  description?: string;
  creator: number;
  clients?: ListUserInterface[];
  category: string;
  assessment_config: AssessmentConfigItem[];
  created?: string;
  modified?: string;

  constructor(data: Partial<Assessment> = {}) {
    this.id = data.id ?? undefined;
    this.name = data.name ?? '';
    this.description = data.description;
    this.creator = data.creator ?? 0;
    this.clients = data.clients ?? [];
    this.category = data.category ?? '';
    this.assessment_config = data.assessment_config ?? [];
    this.created = data.created ?? undefined;
    this.modified = data.modified ?? undefined;
  }
}

export class AssessmentResult {
  id?: number;
  assessment: number;
  results: AssessmentConfigItem[];
  client: number | null;
  assessor: number;
  assessment_date : string;
  comment: string;
  created?: string;
  modified?: string;

  constructor(data: Partial<AssessmentResult> = {}) {
    this.id = data.id ?? undefined;
    this.assessment = data.assessment ?? 0;
    this.results = data.results ?? [];
    this.client = data.client ?? null;
    this.assessor = data.assessor ?? 0;
    this.assessment_date  = data.assessment_date  ?? '';
    this.comment = data.comment ?? '';
    this.created = data.created ?? undefined;
    this.modified = data.modified   ?? undefined;
  }
}