export interface ListUserInterface {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  is_active: boolean;
}
export interface User {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  account_type: AccountType;
  is_staff: boolean;
}

export interface UserSettings {
  timezone: string,
  locale: string, 
  mass: string, 
  distance: string, 
  length: string
}

export interface UserMetrics {
  height: string, 
  weight: string,
  gender: string,
  dob: string, 
  hr_resting: string, 
  hr_max: string
}

export interface AccountDetails extends User {
  coaches: ListUserInterface[];
  manager: ListUserInterface, 
  org: {id: number, name: string} | null, 
  club: {id: number, name: string} | null, 
  address_1: string | null, 
  address_2: string | null, 
  phone_number: string | null,
  settings: UserSettings, 
  metrics: UserMetrics
}

export enum AccountType {
  Client = "CLIENT",
  Coach = "COACH",
  Manager = "MANAGER",
}
