import { put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import React from "react";

export function* openRepeatWorkoutDialogSaga(action: actions.OpenRepeatWorkoutDialog) {
    let RepeatWorkoutDialog: any
    yield RepeatWorkoutDialog = React.lazy(() => import("../../coachApp/programs/dialogs/RepeatWorkoutDialog"));
    yield put(actions.setDialogChildrenSuccess({element: RepeatWorkoutDialog, props: action.payload}));
}

export function* openCreateWorkoutDialogSaga(action: actions.OpenCreateWorkoutDialog) {
    let CreateWorkoutDialog: any
    yield CreateWorkoutDialog = React.lazy(() => import("../../coachApp/programs/dialogs/WorkoutDialog"));
    yield put(actions.setDialogChildrenSuccess({element: CreateWorkoutDialog, props: action.payload}));
}

export function* openCreateAssessmentSaga(action: actions.OpenCreateAssessment) {
    let CreateAssessmentDialog: any
    yield CreateAssessmentDialog = React.lazy(() => import("../../coachApp/assessments/forms/AssessmentCreate"));
    yield put(actions.setDialogChildrenSuccess({element: CreateAssessmentDialog, props: action.payload}));
}
export function* openCreateWarmupDialogSaga(action: actions.OpenCreateWarmupDialog) {
    let CreateWarmupDialog: any
    yield CreateWarmupDialog = React.lazy(() => import("../../coachApp/programs/dialogs/forms/CreateWarmupForm"));
    yield put(actions.setDialogChildrenSuccess({element: CreateWarmupDialog, props: action.payload}));
}
export function* openWarmupCooldownDetailsDialogSaga(action: actions.OpenWarmupCooldownDetailsDialog) {
    let WarmUpDetailsDialog: any
    yield WarmUpDetailsDialog = React.lazy(() => import("../../coachApp/programs/dialogs/components/WarmupCooldownDetails"));
    yield put(actions.setDialogChildrenSuccess({element: WarmUpDetailsDialog, props: action.payload}));
}
export function* openAddWarmupDialogSaga(action: actions.OpenAddWarmupDialog) {
    let AddWarmupDialog: any
    yield AddWarmupDialog = React.lazy(() => import("../../coachApp/programs/dialogs/AddWarmupDialog"));
    yield put(actions.setDialogChildrenSuccess({element: AddWarmupDialog, props: action.payload}));
}
export function* openAddCooldownDialogSaga(action: actions.OpenAddCooldownDialog) {
    let AddCooldownDialog: any
    yield AddCooldownDialog = React.lazy(() => import("../../coachApp/programs/dialogs/AddCooldownDialog"));
    yield put(actions.setDialogChildrenSuccess({element: AddCooldownDialog, props: action.payload}));
}
export function* openAssessmentResultCreateSaga(action: actions.OpenCreateAssessmentResult) {
    let CreateAssessmentResultDialog: any
    yield CreateAssessmentResultDialog = React.lazy(() => import("../../coachApp/assessments/forms/AssessmentResultCreate"));
    yield put(actions.setDialogChildrenSuccess({element: CreateAssessmentResultDialog, props: action.payload}));
}

export function* appSaga() {
  yield takeLatest(actions.OPEN_REPEAT_WORKOUT_DIALOG, openRepeatWorkoutDialogSaga)
  yield takeLatest(actions.OPEN_CREATE_WORKOUT_DIALOG, openCreateWorkoutDialogSaga)
  yield takeLatest(actions.OPEN_CREATE_ASSESSMENT, openCreateAssessmentSaga)
  yield takeLatest(actions.OPEN_CREATE_WARMUP_DIALOG, openCreateWarmupDialogSaga)
  yield takeLatest(actions.OPEN_WARMUP_COOLDOWN_DETAILS_DIALOG, openWarmupCooldownDetailsDialogSaga)
  yield takeLatest(actions.OPEN_ADD_WARMUP_DIALOG, openAddWarmupDialogSaga)
  yield takeLatest(actions.OPEN_ADD_COOLDOWN_DIALOG, openAddCooldownDialogSaga)
  yield takeLatest(actions.OPEN_CREATE_ASSESSMENT_RESULT, openAssessmentResultCreateSaga)
}
